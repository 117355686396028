import Api from './api';

class techDataApi extends Api {
  constructor(backendEndPoint, apiKey) {
    const headers = {'X-Api-Key': apiKey};
    super(backendEndPoint, headers);
  }

  getTechData(ciscode) {
    return this.makeGet(`/modelVariantByCisCode/${ciscode}`)
    .then(result => {
        return `Förbr. l/100 km bl. körn.: WLTP: ${result.variantItems[0].wltpFuelConsumptionFrom}-${result.variantItems[0].wltpFuelConsumptionTo}. NEDC korr: ${result.variantItems[0].nedcFuelConsumptionFrom}-${result.variantItems[0].nedcFuelConsumptionTo}. CO2 g/km: WLTP: ${result.variantItems[0].wltpCo2EmissionFrom}-${result.variantItems[0].wltpCo2EmissionTo}. NEDC korr: ${result.variantItems[0].nedcCo2EmissionFrom}-${result.variantItems[0].nedcCo2EmissionTo}. Miljöklass: ${result.environmentalClassification}`;
    });
  }
}

export default new techDataApi(window.TECH_DATA_URL, window.TECH_DATA_API_KEY);
