import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  gridItem: {
    padding: theme.spacing.unit * 2,
  },
  cardAction: {
    display: 'block',
    textAlign: 'initial',
    width: '100%',
  },
});

class Index extends React.Component {
  render() {
    return (
      <div className={this.props.classes.root}>
        <Grid container>
          <Grid item xs={12} md={6} className={this.props.classes.gridItem}>
            <Card>
              <ButtonBase
                className={this.props.classes.cardAction}
                href="/sellers">
                <CardContent>
                  <Typography variant="h4" component="h2">Säljare</Typography>
                </CardContent>
              </ButtonBase>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} className={this.props.classes.gridItem}>
            <Card>
              <ButtonBase
                className={this.props.classes.cardAction}
                href="/cars">
                <CardContent>
                  <Typography variant="h4" component="h2">Bilar</Typography>
                </CardContent>
              </ButtonBase>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Index);
