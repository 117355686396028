import React from 'react';
import { observer } from 'mobx-react';
import { TextField } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import pickerStore from '../../../../stores/pickerStore';
import AddDialog from './AddDialog';

import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    Checkbox
} from '@material-ui/core';


class OptionTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            extraPackages: [],
        };
    }

    addPackage = (name, description, price) => {
        this.setState(prevState => ({
            extraPackages: [
                ...prevState.extraPackages, {
                    name: name,
                    price: price,
                    description: description,
                    custom: true
                  }
            ]
        }));

        // Adding new package to selectedItems
        pickerStore.options[0].selectedItems.push({name: name, price: price, description: description, custom: true})
    }

    handleCheckbox(e) {
        const name = e.target.attributes['name'].value;
        const description = e.target.attributes['description'].value;
        const price = e.target.attributes['price'].value;
        //const id = e.target.attributes['id'].value;
        const checked = e.target.checked;

        if(checked === false) {
            var index = pickerStore.options[0].selectedItems.map(x => {
              return x.name;
          }).indexOf(name);

            pickerStore.options[0].selectedItems.splice(index, 1);
        } else if(checked === true) {
            pickerStore.options[0].selectedItems.push({name: name, price: Number(price), description: description, custom: true})
        }
    }

    render() {
        const option = this.props.option;
        return <div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox">
                            <Checkbox
                                checked={option.areAllItemsSelected}
                                onChange={() => option.toggleAllItems()}
                            />
                        </TableCell>
                        <TableCell>ID</TableCell>
                        <TableCell>Namn</TableCell>
                        {option.hasDescription && <TableCell>Beskrivning</TableCell>}
                        {!option.hasDescription && <TableCell>Standard</TableCell>}
                        <TableCell>Pris</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {option.items.map((item, index) => {
                        return <TableRow key={index}>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={option.isItemSelected(item)}
                                    onChange={() => option.toggleItem(item)}
                                />
                            </TableCell>
                            <TableCell>{item.id}</TableCell>
                            <TableCell>{item.name}</TableCell>
                            {option.hasDescription && (
                                <TableCell>
                                    <div dangerouslySetInnerHTML={{ __html: item.description }}/>
                                </TableCell>
                            )}
                            {!option.hasDescription && (
                                <TableCell>
                                    <Radio
                                        checked={item.default}
                                        disabled={!option.isItemSelected(item)}
                                        onChange={(event) => option.onItemRadioChanged(item, 'default', event.target.checked)}
                                        name="colorRadio"
                                  />
                                </TableCell>
                            )}
                            <TableCell>
                                <TextField
                                    value={item.price}
                                    onChange={(event) => option.onItemChanged(item, 'price', event.target.value)}
                                    margin="none"
                                    disabled={!option.isItemSelected(item)}
                                />
                            </TableCell>
                        </TableRow>
                    })}


                    {/* ---- Renders added packages ---- */}
                    {
                        pickerStore.selectedOptionTab.key === 'package' && Object.keys(pickerStore.carData).length ?
                        pickerStore.carData.price[0].additional.map((item) => {
                            if(item.custom === true) {
                                return <TableRow key={item._id}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            inputProps={{id: item._id, name: item.name, description: item.description, price: item.price}}
                                            defaultChecked={true}
                                            onChange={this.handleCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell>Custom</TableCell>
                                    <TableCell>{item.name}</TableCell>
                                        <TableCell>
                                            <div dangerouslySetInnerHTML={{ __html: item.description }}/>
                                        </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={item.price}
                                            margin="none"
                                        />
                                    </TableCell>
                                </TableRow>
                            } else { return null }
                        })
                        : null
                    }


                    {/* ---- Adding new packages ---- */}
                    {
                        pickerStore.selectedOptionTab.key === 'package' ?
                        this.state.extraPackages.map((item, index) => {
                            if(item.custom === true) {
                                return <TableRow key={item._id}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            inputProps={{id: item._id, name: item.name, description: item.description, price: item.price}}
                                            defaultChecked={true}
                                            onChange={this.handleCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell>Custom</TableCell>
                                    <TableCell>{item.name}</TableCell>
                                        <TableCell>
                                            <div dangerouslySetInnerHTML={{ __html: item.description }}/>
                                        </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={item.price}
                                            margin="none"
                                        />
                                    </TableCell>
                                </TableRow>
                            } else { return null }
                        })
                        : null
                    }

                </TableBody>
            </Table>
            {
                pickerStore.selectedOptionTab.key === 'package' ?
                    <AddDialog
                        addPackage={this.addPackage}
                    />
                : null
            }

        </div>
    }
}

export default observer(OptionTable);
