import { action, computed, decorate, observable } from 'mobx';

export class OptionStore {
    key;
    menuId;
    label;
    hasDescription = false;
    menuItems = [];
    selectedItems = [];

    constructor(key, menuId, label, hasDescription, menuItems = [], selectedItems = []) {
        this.key = key;
        this.menuId = menuId;
        this.label = label;
        this.hasDescription = hasDescription;
        this.menuItems = menuItems;
        this.selectedItems = selectedItems;
    }

    updateMenuItems(items) {
        this.menuItems.replace(items);
        this.selectedItems.replace(
            this.selectedItems.filter(
                (selectedItem) => this.menuItems.find(
                    (menuItem) => menuItem.id === selectedItem.id
                ) || selectedItem.custom === true
            )
        );
    }

    updateSelectedItems(items) {
        this.selectedItems.replace(items);
    }

    toggleItem(item) {
        const index = this.selectedItems.findIndex((it) => it.id === item.id);
        if (index === -1) {
            this.selectedItems.push(item);
            return;
        }

        this.selectedItems.splice(index, 1);
    }

    toggleAllItems() {
        if (this.areAllItemsSelected) {
            this.selectedItems.replace([]);
            return;
        }

        this.selectedItems.replace(
            this.menuItems.map(this.convertMenuItemToItem)
        );
    }

    onItemRadioChanged(item, field, value) {
        this.selectedItems.map((item, index) => {
            return item.default = false;
        })
        this.selectedItems.find((it) => it.id === item.id)[field] = value;
    }

    onItemChanged(item, field, value) {
        if (!this.isItemSelected(item)) {
            return;
        }
        this.selectedItems.find((it) => it.id === item.id)[field] = value;
    }

    isItemSelected(item) {
        return this.selectedItems.findIndex(
            (selectedItem) => selectedItem.id === item.id
        ) !== -1;
    }

    convertMenuItemToItem(item) {
        return {
            id: item.id,
            name: item.shortText,
            description: item.longText,
            price: 0.00,
            cis: item.id.split('~')[1],
            default: false
        };
    }

    get areAllItemsSelected() {
        return this.selectedItems.length === this.items.length;
    }

    get items() {

        return this.menuItems
            .map(this.convertMenuItemToItem)
            .map((item) => {
                return this.selectedItems.find(
                    (selectedItem) => selectedItem.id === item.id
                ) || item;
            });
    }

    get allItems() {
        return [
            ...this.items,
            ...this.selectedItems.filter((item) => {
                return this.items.findIndex((i) => i.id === item.id) === -1;
            })
        ];
    }

    asJson() {
        return this.selectedItems.map((item) => ({
            id: item.id,
            name: item.name,
            description: item.description,
            price: item.price,
            cis: item.cis
        }));
    }
}

decorate(OptionStore, {
    key: observable,
    menuId: observable,
    label: observable,
    hasDescription: observable,
    menuItems: observable,
    selectedItems: observable,
    items: computed,
    allItems: computed,
    areAllItemsSelected: computed,
    toggleItem: action,
    toggleAllItems: action
});

export default OptionStore;
