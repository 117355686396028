import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import SellerItem from './SellerItem';
import Api from '../../lib/cbvApi';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    padding: theme.spacing.unit * 2,
  },
});

class SellerList extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.addSeller = this.addSeller.bind(this);

    this.state = {
      sellers: [],
    };
  }

  componentDidMount() {
    Api.getSellers().then(sellers => {
      this.setState({
        sellers: sellers,
      });
    }).catch(e => {
      console.log(e);
    });
  }

  onChange(index, id, seller) {
    Api.updateSeller(id, seller).then(result => {
      this.setState(prevState => {
        const sellers = prevState.sellers;
        sellers[index] = {...seller, hash: id};
        return Object.assign({}, prevState, { sellers: sellers })
      });
    });
  }

  onCreate(index, seller) {
    Api.addSeller(seller).then(result => {
      this.setState(prevState => {
        const sellers = prevState.sellers;
        sellers[index] = {...seller, hash: result.hash};
        return Object.assign({}, prevState, { sellers: sellers })
      });
    });
  }

  onDelete(index, hash) {
    Api.deleteSeller(hash).then(() => {
      this.setState(prevState => {
        const sellers = prevState.sellers;
        delete sellers[index];
        return { sellers: sellers };
      })
    });
  }

  addSeller() {
    this.setState((prevState) => ({
      sellers: [{ csid: '', email: '' }, ...prevState.sellers]
    }));
  }

  render() {
    return (
      <Grid container justify="center" >
        <Grid item xs={12} md={8}>

          <Paper elevation={1} className={this.props.classes.container}>
            <Grid item xs className={this.props.classes.section} style={{marginBottom: '15px'}}>
              <Fab color="primary" size="small" aria-label="Tillbaka" component={Link} to="/">
                <Icon>arrow_back</Icon>
              </Fab>

              <Button variant="contained" onClick={this.addSeller} style={{float: 'right'}}>
                <Icon>add</Icon>
              </Button>
            </Grid>

            {this.state.sellers.map((seller, index) =>
              <Grid item xs key={seller.hash}>
                <SellerItem
                  value={seller}
                  onChange={(id, seller) => {return this.onChange(index, id, seller)}}
                  onCreate={(seller) => {return this.onCreate(index, seller)}}
                  onDelete={(hash) => {return this.onDelete(index, hash)}} />
              </Grid>
            )}

          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(SellerList));
