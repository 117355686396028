import { withStyles } from '@material-ui/core/styles';
import { FormLabel } from '@material-ui/core';

const styles = theme => ({
    root: {
        fontSize: 12,
        display: 'block'
    }
});

export default withStyles(styles)(FormLabel);
