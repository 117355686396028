import React from 'react';
import { Select, MenuItem } from '@material-ui/core';
import StyledLabel from '../StyledLabel';
import pickerStore from '../../../../stores/pickerStore';
import { observer } from 'mobx-react';

const emptyString = 'Välj modell...';

const renderValue = (value) => {
    if (!value) {
        return <em>{emptyString}</em>;
    }

    const pickedModel = pickerStore.models.find((model) => model.modelId === value);
    return pickedModel ? pickedModel.name :  emptyString;
}

class ModelSelect extends React.Component {

    handleLangChange = (event) => {
        this.props.handleModelSelect(event);
    }

    render () {
        return (
            <div>
                <StyledLabel>Modell</StyledLabel>
                <Select
                    autoWidth={true}
                    displayEmpty={true}
                    renderValue={renderValue}
                    value={pickerStore.selectedModelId || ""}
                    onChange={(event) => this.handleLangChange(event.target.value)}
                >
                    <MenuItem disabled><em>{emptyString}</em></MenuItem>
                    {pickerStore.models.map((model) => (
                        <MenuItem key={model.modelId} value={model.modelId}>
                            {model.name}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        )
    }
};

export default observer(ModelSelect);
