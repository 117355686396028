import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import pickerStore from '../../stores/pickerStore';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Equipment from '../CarForm/Equipment';
import Cis from '../CarForm/Cis';
import Api from '../../lib/cbvApi';
import dmsApi from '../../lib/dmsApi';
import techDataApi from '../../lib/techDataApi';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    padding: theme.spacing.unit * 2,
  },
  section: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});

class CarForm extends React.Component {
constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleEquipmentChange = this.handleEquipmentChange.bind(this);
    this.addEquipment = this.addEquipment.bind(this);
    this.removeEquipment = this.removeEquipment.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.updateEquipmentList = this.updateEquipmentList.bind(this);
    this.onCISupdate = this.onCISupdate.bind(this);
    this.state = {
        carModel: '',
        cis: '',
        defaultColorSelected: false,
        equipment: [],
        formError: null,
        id: '',
        images: [],
        name: '',
        title: '',
        price: [],
        promoted: false,
        promotedName: '',
        promotedTitle: '',
        tags: [],
        techData: '',
        order: 0
    };
}


handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox'
        ? target.checked
        : target.value;
    const name = target.id;

    this.setState({[name]: value});
}

handleEquipmentChange(id, event) {
    this.setState(prevState => {
        const equipment = prevState.equipment;
        equipment[id] = event;
        return Object.assign({}, prevState, {equipment: equipment})
    });
}

addEquipment() {
    this.setState(prevState => ({
        equipment: [
            ...prevState.equipment, {
                description: ''
            }
        ]
    }));
}

removeEquipment(index) {
    var equipment = [...this.state.equipment];
    equipment.splice(index, 1);
    this.setState({equipment: equipment});
}

validateForm(carData) {
    let formError = false;
    let defaultColor = false;

    let data = {
        name: this.state.name,
        title: this.state.title,
        price: this.state.price,
        tillval: pickerStore.salesVersion,
        color: carData.price[0].colors
    }

    //checking if there is a default color
    carData.price[0].colors.forEach((color, index) => {
        if(color.default === true) {
            defaultColor = true;
            this.setState({
                defaultColorSelected: true
            })
        }
    })

    Object.entries(data).forEach(val => {
        let value = val[1];
        if(value.length < 1) {
            formError = true;
        }
    });


    if(formError === false && defaultColor === true) {
        console.log('saving car!');
        this.setState({
            formError: false
        })

        Api.addCar(carData).then((resp) => (resp))
        .then(response => {
            this.props.history.push('/');
        })
        .catch(error => {
            alert('Ett fel inträffade, det gick inte att spara bilen. Fel: ' + error);
            throw error;
        });

    } else {
        console.log('form error!');
        this.setState({
            formError: true
        })
    }
}

handleSave() {
    let name = this.state.name;
    let carModel = pickerStore.selectedModelName;
    let ciscode = pickerStore.cisCode;
    let equipment = this.state.equipment;
    let images = [];
    let priceValue = this.state.price;
    let order = this.state.order;
    let price = [{}];

    let promoted = this.state.promoted;
    let tags = [];
    let techData = this.state.techData;
    let title = this.state.title;
    let additional = [];
    let colors = [];
    let popular = this.state.popular;
    let promotedName = this.state.promotedName;
    let promotedTitle = this.state.promotedTitle;

    // Tillval
    pickerStore.options[0].selectedItems.forEach((item, index) => {
        additional.push({
            id: item.id,
            name: item.name,
            price: parseInt(item.price),
            description: item.description ? item.description : ' ',
            custom: item.custom ? item.custom : false
        })
    })

    // Colors
    pickerStore.options[1].selectedItems.forEach((item, index) => {
        colors.push({
            id: item.id,
            cis: item.cis,
            price: parseInt(item.price),
            name: item.name,
            default: item.default,
            icon: window.CBV_BACKEND_URL + '/icons/' + pickerStore.client.client.sessionState.categoryId + '/' + pickerStore.selectedModelId + '/' + item.id + '.png'
        })
    })

    price[0].additional = additional;
    price[0].colors = colors;
    price[0].basePrice = parseInt(priceValue);
    price[0].months = 36;
    price[0].mileage = 1500;

    // Popular
    if (popular) {
        for (var i=tags.length-1; i>=0; i--) {
            if (tags[i] === 'Popular') {
                tags.splice(i, 1);
            }
        }

        tags.push('Popular')
    }

    let carData = {
        name,
        carModel,
        ciscode,
        equipment,
        images,
        order,
        price,
        promoted,
        promotedName,
        promotedTitle,
        tags,
        techData,
        title
    }
    // Adding default color code to CIS
    for (var y=0, iLen=carData.price[0].colors.length; y<iLen; y++) {
        if (carData.price[0].colors[y].default === true) {
            var newCis = carData.ciscode.substring(0, 14) + carData.price[0].colors[y].cis + carData.ciscode.substring(17);
            carData.ciscode = newCis;
        }
    }

    // Genereating new image links with the default color code
    var hostImg = 'https://static.fbinhouse.se/sparkProxy/';
    var imgsize = '1200x/';
    var interior = '/INTERIOR/';
    var exterior = '/EXTERIOR/';

    var imageArr = [];

    for (var x = 1; x < 10; x++) {
        if (x < 7) {
            imageArr.push(hostImg + imgsize + carData.ciscode + exterior + x + '.png')
        } else {
            imageArr.push(hostImg + imgsize + carData.ciscode + interior + (x - 6) + '.png')
        }
    }

    carData.images = imageArr;

    this.validateForm(carData);
}

    updateEquipmentList(salesVersion) {
        let modelId = Number(pickerStore.cisCode.substring(2, 5));
        let salesVersionId = salesVersion ? salesVersion : pickerStore.salesVersion;

        //this.setState({equipment: []});

        dmsApi.getSalesVersions()
          .then(result => {
            const filtered = result.filter(salesVersion => {
              return salesVersion.values.menu_id === salesVersionId;
            });

            const reFiltered = filtered.filter(salesVersion => {
              return salesVersion.modelCodes.includes(modelId);
            });

            return reFiltered;
          })
          .then(result => {
            let eqArr = [];
            if(result[0]) {
                result[0].values.options.option.forEach((item, index) => {
                    if(item.name) {
                        eqArr.push({
                            description: item.name,
                            additional: item.description ? item.description : null
                        })
                    }
                })
                this.setState({equipment: []});
                this.setState({equipment: eqArr});
            }
        });
    }

     getTechData(ciscode) {
         return techDataApi.getTechData(ciscode);
     }

    onCISupdate(val) {
        if(val) {
            this.updateEquipmentList(val);
        }

        if(
          pickerStore.selectedModelId &&
          pickerStore.cisCode &&
          pickerStore.cisCode !== this.state.cis
        ) {
           this.getTechData(pickerStore.cisCode)
           .then(result => {
               this.setState({
                   techData: result
               });
           });
        }
    }

  render() {
      console.log(this.state.equipment);
    return (
      <div className={this.props.classes.root}>
        <form>
          <Grid container justify="center" >
            <Grid item xs={12} md={8}>
              <Paper elevation={1} className={this.props.classes.container}>
                <Grid item xs className={this.props.classes.section}>
                  <Fab color="primary" size="small" aria-label="Tillbaka" component={Link} to="/cars">
                    <Icon>arrow_back</Icon>
                  </Fab>
                </Grid>
                <Grid item xs className={this.props.classes.section}>
                    <TextField
                        required
                        fullWidth
                        id="name"
                        label="Namn"
                        margin="normal"
                        value={this.state.name}
                        onChange={this.handleChange}
                        error={this.state.name.length <= 0 && this.state.formError ? true : false} />

                    <TextField
                        required
                        fullWidth
                        id="title"
                        label="Titel"
                        margin="normal"
                        value={this.state.title}
                        onChange={this.handleChange}
                        error={this.state.title.length <= 0 && this.state.formError ? true : false} />

                    <TextField
                        required
                        fullWidth
                        id="price"
                        label="Baspris"
                        margin="normal"
                        value={this.state.price}
                        onChange={this.handleChange}
                        error={this.state.price.length <= 0 && this.state.formError ? true : false} />

                    <TextField
                        fullWidth
                        id="order"
                        label="Sortering"
                        margin="normal"
                        value={this.state.order}
                        onChange={this.handleChange} />

                    <TextField
                        fullWidth
                        id="techData"
                        label="Teknisk data"
                        margin="normal"
                        value={this.state.techData}
                        onChange={this.handleChange} />
                </Grid>

                <Grid item xs className={this.props.classes.section} style={{display: 'flex', alignItems: 'center', paddingTop: '0'}}>
                    <Typography variant="subheading">
                      Popular:
                    </Typography>
                    <Checkbox
                        onChange={this.handleChange}
                        checked={this.state.popular ? true : false}
                        id="popular"
                    />

                    <Typography variant="subheading">
                      Promotad:
                    </Typography>
                    <Checkbox
                        onChange={this.handleChange}
                        checked={this.state.promoted ? true : false}
                        id="promoted"
                    />
                </Grid>

                { this.state.promoted ? <Grid>
                    <TextField
                        style={{marginTop: 0, marginBottom: '20px'}}
                        fullWidth
                        id="promotedName"
                        label="Titel för Promotad bil"
                        margin="normal"
                        value={this.state.promotedName}
                        onChange={this.handleChange} />
                </Grid> : null }

                { this.state.promoted ? <Grid>
                    <TextField
                        style={{marginTop: 0, marginBottom: '20px'}}
                        fullWidth
                        id="promotedTitle"
                        label="Subtitle för Promotad bil"
                        margin="normal"
                        value={this.state.subtitle}
                        onChange={this.handleChange} />
                </Grid> : null }

                <Grid item xs className={this.props.classes.section}>
                    <Cis
                        value={this.state.cis}
                        updateEquipmentList={this.updateEquipmentList}
                        onCISupdate={this.onCISupdate}
                    />
                </Grid>

                <Grid item xs className={this.props.classes.section}>
                  <Typography component="h2" variant="h5">
                    Utrustning
                  </Typography>

                  {this.state.equipment.map((item, index) =>
                    <Equipment
                      equipment={item}
                      onChange={e => {this.handleEquipmentChange(index, e)}}
                      onRemove={this.removeEquipment}
                      key={index}
                      id={index} />
                  )}

                  <Button variant="contained" onClick={this.addEquipment}>
                    <Icon>add</Icon>
                  </Button>
                </Grid>

                <Grid item xs className={this.props.classes.section}>
                  <Button variant="contained" color="primary" onClick={this.handleSave}>
                    Lägg till
                  </Button>
                  {
                      this.state.formError && pickerStore.salesVersion.length < 1
                      ? <p style={{color: '#ee5661'}}>Vänligen välj en modell</p>
                      : null
                  }
                  {
                      this.state.formError && pickerStore.salesVersion.length > 1 && !this.state.defaultColorSelected
                      ? <p style={{color: '#ee5661'}}>Vänligen välj en default färg</p>
                      : null
                  }
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(CarForm));
