import Api from './api';

class dmsApi extends Api {
  constructor(backendEndPoint) {
    super(backendEndPoint, {});
  }

  getSalesVersions() {
    return this.makeGet('/266/?campaign=sales_versions');
  }
}

export default new dmsApi(window.DMS_URL)
