import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';

const styles = theme => ({
  media: {
    height: 0,
    paddingTop: '42.66667%',
  }
});

class CarListItem extends React.Component {

  removeCar() {
    const r = window.confirm("Vill du tabort " + this.props.name + " ?");
    if(r === true) {
      this.props.onRemoveCar(this.props.index, this.props.id);
    }
  }

  onOrderChange(e) {
      let id = this.props.id;
      let val = e.target.value;
      this.props.onOrderChange(id, val);
  }

  render() {
    return (
      <Card style={{flex: '1'}}>
        <CardHeader
          style={{position: 'absolute', top: '20px', left: '20px'}}
          title={this.props.name}
          subheader={this.props.title} />

        <CardMedia
          style={{position: 'relative', top: '30px', left: '0', margin: '50px 0'}}
          image={this.props.image}
          title={this.props.title}
          className={this.props.classes.media} />

        <TextField
          label="Sortering"
          margin="normal"
          onChange={this.onOrderChange.bind(this)}
          style={{marginLeft: '20px', marginBottom: '20px'}}
          value={this.props.order}
        />

        <CardActions>
          <Button style={{position: 'absolute', bottom: '20px', left: '20px'}} size="small" color="primary" component={Link} to={`/cars/${this.props.id}`}>
            Redigera
          </Button>
        </CardActions>

        <CardActions>
          <Button style={{position: 'absolute', bottom: '20px', right: '20px', color: '#eb3a47'}} size="small" color="primary" onClick={this.removeCar.bind(this)}>
            Ta bort
          </Button>
        </CardActions>

      </Card>
    );
  }
}

export default withStyles(styles)(CarListItem);
