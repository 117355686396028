import React from 'react';
import { Select, MenuItem } from '@material-ui/core';
import StyledLabel from '../StyledLabel';
import { observer } from 'mobx-react';

const renderValue = (props) => (value) => {
    if (!value) {
        return <em>{props.emptyString}</em>;
    }

    return props.items.find((item) => item.id === value).shortText;
}

export const MenuSelect = observer(({ menu, onChange }) => (
    <div>
        <StyledLabel>{menu.label}</StyledLabel>
        <Select
            autoWidth={true}
            displayEmpty={true}
            renderValue={renderValue(menu)}
            value={menu.value}
            onChange={onChange}
        >
            <MenuItem disabled><em>{menu.emptyString}</em></MenuItem>
            {menu.items.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                    {item.shortText}
                </MenuItem>
            ))}
        </Select>
    </div>
));

export default MenuSelect;
