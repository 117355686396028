import React from 'react';
import ModelSelect from './components/ModelSelect';
import MenuSelect from './components/MenuSelect';
import OptionTable from './components/OptionTable';
import { Grid, Tabs, Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import StyledLabel from './components/StyledLabel';
import pickerStore from '../../stores/pickerStore';
import { observer } from 'mobx-react';
import { toJS } from 'mobx'

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    options: {
        marginTop: 40
    }
});

class Cis extends React.Component {

    async componentDidMount() {
        await pickerStore.init(this.props.value);
    }

    /*onModelChange = async (event) => {
        const modelId = event.target.value;
        pickerStore.changeModel(modelId);
    }*/

    onMenuChange = (key) => async (event) => {
        if(key === 'salesVersion') {
            this.props.updateEquipmentList(event.target.value)

            // Resetting selected values on model change
            pickerStore.options[0].selectedItems = [];
            pickerStore.options[1].selectedItems = [];
        }
        pickerStore.selectMenuItem(key, event.target.value);
    }

    onOptionTabChanged = (event, value) => {
        pickerStore.selectedOptionsTabKey = value;
    }

    handleModelSelect = (val) => {
        pickerStore.changeModel(val).then(() => {
            this.props.updateEquipmentList()
        })
    }


    renderOptions = () => {
        this.props.onCISupdate(toJS(pickerStore.menus[2].value))
        return (
            <div className={this.props.classes.options}>
                <StyledLabel>Tillval</StyledLabel>
                <Tabs value={pickerStore.selectedOptionTab.key} onChange={this.onOptionTabChanged}>
                    {pickerStore.options.map((option) => (
                        <Tab label={option.label} key={option.key} value={option.key}/>
                    ))}
                </Tabs>
                <OptionTable
                    option={pickerStore.selectedOptionTab}
                />
            </div>
        );
    }

    render() {
        return (
            <div className={this.props.classes.root}>
                <Grid container spacing={16}>
                    <Grid item xs>
                        <ModelSelect handleModelSelect={this.handleModelSelect}/>
                    </Grid>
                    {pickerStore.menus.filter((menu) => menu.items.length).map((menu) => (
                        <Grid item xs key={menu.key}>
                            <MenuSelect
                                menu={menu}
                                onChange={this.onMenuChange(menu.key)}
                            />
                        </Grid>
                    ))}
                </Grid>

                {this.renderOptions()}

            </div>
        );
    }
}

export default withStyles(styles)(observer(Cis));
