import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';


export default class FormDialog extends React.Component {
  state = {
    open: false,
    name: '',
    price: 0,
    description: ''
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };


  onNameChange = (event) => {
      const target = event.target;
      const value = target.value;

      this.setState({
          name: value
      })
  }

  onDescriptionChange = (event) => {
      const target = event.target;
      const value = target.value;

      this.setState({
          description: value
      })
  }

  onPriceChange = (event) => {
      const target = event.target;
      const value = target.value;

      this.setState({
          price: value
      })
  }

  addPackage = () => {
      this.props.addPackage(this.state.name, this.state.description, this.state.price);
      this.handleClose();

      this.setState({
          name: '',
          description: '',
          price: 0
      })
  }


  render() {
    return (
      <div>

        <Button variant="contained" style={{marginTop: '20px'}} onClick={this.handleClickOpen}>
          <Icon>add</Icon>
        </Button>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="Lägg-till-paket"
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title">Lägg till paket</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Namn"
              type="text"
              fullWidth
              onChange={this.onNameChange}
            />
          </DialogContent>

          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="description"
              label="Beskrivning"
              type="text"
              fullWidth
              multiline={true}
              onChange={this.onDescriptionChange}
            />
          </DialogContent>

          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="price"
              label="Pris"
              type="number"
              fullWidth
              onChange={this.onPriceChange}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Avbryt
            </Button>
            <Button onClick={this.addPackage} color="primary">
              Lägg till
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
