import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import auth from './lib/auth';
import * as serviceWorker from './serviceWorker';

const renderApplication = () => {
  ReactDOM.render(<App />, document.getElementById('root'));
}

auth.handleAuthentication()
  .then((authed, err) => {

    if (!auth.isAuthenticated()) {
      return auth.login();
    }

    if (authed) {
      window.location.href = '/';
    }

    renderApplication(App);
  })
  .catch((err) => {
      auth.logout();
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
