import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Api from '../../lib/cbvApi';
import CarListItem from './CarListItem';
import pickerStore from '../../stores/pickerStore';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  gridItem: {
    padding: theme.spacing.unit * 2,
  },
});

class CarList extends React.Component {
  constructor(props) {
    super(props);

    this.onRemoveCar = this.onRemoveCar.bind(this);
    this.onOrderChange = this.onOrderChange.bind(this);

    this.state = {
      cars: [],
    };
  }

  componentDidMount() {
      // Reset values
      pickerStore.menus[2].value = ''; // eq list

      // Selected car reset
      pickerStore.carData = {};

      // Selected items reset
      pickerStore.options[0].selectedItems = []
      pickerStore.options[1].selectedItems = []

    Api.getCars().then(cars => {
      this.setState({ cars: cars });
    }).catch(e => {
      console.log(e);
    });
  }

  onSelect(carId) {
    this.props.onSelect(carId);
  }

  onRemoveCar(index, id) {
    const cars = this.state.cars;
    delete cars[index];

    Api.deleteCar(id).then(() => {
      this.setState({
        cars: cars
      });
    });
  }

  saveCarList() {
      this.state.cars.forEach((carData) => {
          Api.updateCar(carData._id, carData).then(() => {
            document.location.reload()
          });
      })
  }

  onOrderChange(id, val) {
      let cars = this.state.cars;

      let currentCar = cars.find(obj => {
          return obj._id === id
      })

      currentCar.order = parseInt(val);

      this.setState({
          cars: cars
      })
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <Grid container>
          {this.state.cars.map((car, index) =>
            <Grid style={{position: 'relative'}} item xs={12} md={6} className={this.props.classes.gridItem} key={index}>
              <CarListItem
                index={index}
                id={car._id}
                name={car.name}
                title={car.title}
                image={car.images[0]}
                onRemoveCar={this.onRemoveCar}
                onOrderChange={this.onOrderChange}
                order={car.order ? car.order : 0}
             />
            </Grid>
          )}
        </Grid>

        <Grid item xs className={this.props.classes.section} style={{marginLeft: '20px'}}>
          <Button variant="contained" component={Link} to="/addCar">
            <Icon>add</Icon>
          </Button>

          <Button variant="contained" style={{marginLeft: '10px'}} onClick={() => this.saveCarList()}>
            Spara
          </Button>
        </Grid>

      </div>
    );
  }
}

export default withStyles(styles)(CarList);
