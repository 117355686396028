import auth0 from 'auth0-js';
import store from 'store';

class AuthService {
    constructor(options) {
        this.options = options;

        this.auth0 = new auth0.WebAuth({
            domain: options.domain,
            audience: options.audience,
            clientID: options.clientId,
            redirectUri: options.authUri,
            responseType: 'token id_token',
            scope: 'openid profile'
        });
    }

    login() {
        this.auth0.authorize();
    }

    handleAuthentication() {
        return new Promise((resolve, reject) => {
            this.auth0.parseHash((err, authResult) => {
                if (authResult && authResult.accessToken && authResult.idToken) {
                    window.location.hash = '';
                    this.setSession(authResult);

                    this.auth0.client.userInfo(authResult.accessToken, function(err, user) {
                        store.set('userId', user.sub.split('|')[1]);
                        resolve(true);
                    });

                    return;
                } else if (err) {
                    return reject(false, err);
                }

                resolve(false);
            });
        });
    }

    isAuthenticated() {
        if (!store.get('expires_at')) {
            return false;
        }

        const expiresAt = JSON.parse(store.get('expires_at'));
        return new Date().getTime() < expiresAt;
    }

    logout() {
        store.remove('id_token');
        store.remove('token');
        store.remove('expires_at');

        clearTimeout(this.tokenRenewalTimeout);

        this.auth0.logout({
            returnTo: this.options.logoutUri
        });
    }

    setSession(authResult) {
        const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());

        store.set('id_token', authResult.idToken);
        store.set('token', authResult.accessToken);
        store.set('expires_at', expiresAt);

        this.scheduleRenewal();
    }

    renewToken() {
        this.auth0.checkSession({}, (err, result) => {
            if (err) {
                return this.login();
            }

            this.setSession(result);
        });
    }

    scheduleRenewal() {
        const expiresAt = JSON.parse(store.get('expires_at'));
        const delay = expiresAt - Date.now();

        if (delay > 0) {
            this.tokenRenewalTimeout = setTimeout(() => {
                this.renewToken();
            }, delay);
        }
    }
}

const auth = new AuthService({
    domain: 'auth.fbinhouse.se',
    audience: 'http://vcs-care-by-volvo/',
    clientId: '1hmPdfm16C1yrJDzq2ofrvYMHwtrwp8N',
    redirectUri: window.location.origin + '/auth',
    authUri: window.location.origin + '/auth',
    logoutUri: window.location.origin
});

export default auth;
