import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  container: {
    padding: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
});

class Equipment extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.remove = this.remove.bind(this);
    this.state = {
      description: '',
      additional: '',
    };
  }

  componentDidMount() {
    this.setState({
      description: this.props.equipment.description || '',
      additional: this.props.equipment.additional || '',
    });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.props.onChange(Object.assign({}, this.state, {[name]: value}));

    this.setState({
      [name]: value
    });
  }

  remove() {
    this.props.onRemove(this.props.id);
  }

  render() {
    return (
      <Paper elevation={1} className={this.props.classes.container}>
        <Grid container>
          <Grid item xs>
            <TextField
              required
              fullWidth
              id={`description-${this.props.id}`}
              name="description"
              label="Beskrivning"
              margin="normal"
              value={this.state.description}
              onChange={this.handleChange} />
            <TextField
              fullWidth
              id={`title-${this.props.id}`}
              name="additional"
              label="Ytterligare"
              margin="normal"
              value={this.state.additional}
              onChange={this.handleChange} />
          </Grid>
          <Grid item>
            <Fab
              size="small"
              aria-label="Ta bort"
              onClick={this.remove}>
              <Icon>remove</Icon>
            </Fab>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(Equipment);
